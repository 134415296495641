// framework
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { BsBasket3Fill } from "react-icons/bs";
// kendo
import { DataResult, State } from "@progress/kendo-data-query";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { FilterChangeEvent } from "@progress/kendo-react-data-tools";
// common
import ODataReader from "../../../common/odata/ODataReader";
import CustomGridCell from "../../../common/kendoGrid/CustomGridCell";
import { useUserToken } from "../../../common/shell/shellHelper";
import * as GlobalHelpers from "../../../common/GlobalHelpers";
import ExternalLinkView from "../../../common/externalLink/ExternalLinkView";
import GridFilter from "../../../common/kendoGrid/GridFilter";
import { getTextFilter } from "../../../common/kendoGrid/GridFilterOptions";
import { TextBoxFilter } from "../../../common/kendoGrid/GridFilterTextBoxFilter";
// basket
import * as Actions from "../../basket/redux/actions";
import { useNopimsBasket } from "../../basket/redux/reducers";
import { NopimsBasketItemModel } from "../../basket/redux/model";
// models
import { IReportDataRow } from "./models";

interface IProps {
    selectionVersion: number;
}

export default function Controller(props: IProps) {
    const userToken = useUserToken();
    const nopimsBasketModel = useNopimsBasket();
    const dispatch = useDispatch();

    const pageSize: number = 10;
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [
            {
                field: "ReprocessingName",
                operator: "contains",
                value: "",
            },
        ],
    };

    const [gridState, setGridDataState] = useState<State>({
        sort: [
            { field: "ReprocessingName", dir: "asc" },
            { field: "ReprocessingProjectName", dir: "asc" },
            { field: "ReportTitle", dir: "asc" },
        ],
        take: pageSize,
        skip: 0,
        filter: initialFilter,
    });

    const [filter, setFilter] = useState<CompositeFilterDescriptor>(initialFilter);
    function onFilterChange(e: FilterChangeEvent): void {
        setFilter(e.filter);
        setGridDataState({ ...gridState, filter: e.filter });
    }

    const [data, setData] = useState<DataResult>({ data: [], total: 0 });
    function onDataChange(data: DataResult): void {
        setData(data);
    }

    function mapDataRow(dataRow: any): IReportDataRow {
        return dataRow;
    }

    // ensure we don't get stuck on an empty grid page
    useEffect(() => {
        if (data.data.length === 0 && data.total > 0 && gridState && gridState.skip! > 0) setGridDataState({ ...gridState, skip: 0 });
    }, [data, gridState]);

    function onBasketAddAll(): void {
        dispatch(Actions.itemsAdded(data.data.map((i) => new NopimsBasketItemModel(i.ReportTitle ?? i.ReportItemId, i.ReportId, undefined, undefined, undefined, i.ReprocessingProjectId))));
    }

    function onBasketAdd(item: IReportDataRow): void {
        dispatch(Actions.itemsAdded([new NopimsBasketItemModel(item.ReportTitle ?? item.ReportItemId, item.ReportId, undefined, undefined, undefined, item.ReprocessingProjectId)]));
    }

    return (
        <>
            <GridFilter
                value={filter}
                onChange={onFilterChange}
                fields={[
                    { name: "AttachmentArchiveFileName", label: "Download", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "ReportTitle", label: "Data Description", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "ReportType", label: "Report Type", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "Company", label: "Company", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "TapeId", label: "Tape ID", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "ReportItemId", label: "Item ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "ReprocessingIdentifier", label: "Reprocessing ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "ReprocessingName", label: "Reprocessing", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "ReprocessingProjectIdentifier", label: "Project ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "ReprocessingProjectName", label: "Project", filter: TextBoxFilter, operators: getTextFilter(false) },
                ]}
            />
            {userToken && (
                <ODataReader
                    url={`odata/v1/public/nopims/reprocessing/PublicNopimsReprocessings/GetReportsForSelection(userToken=${userToken})`}
                    showContentAsBusyElementId="ReprocessingReportsSearchResultsGrid"
                    dataState={gridState}
                    onDataReceived={onDataChange}
                    mapDataRow={mapDataRow}
                    changeToken={props.selectionVersion}
                />
            )}
            <div className="mb-4">
                <Grid
                    id="ReprocessingReportsSearchResultsGrid"
                    sortable
                    pageable={{ pageSizeValue: pageSize, responsive: false }}
                    resizable
                    navigatable
                    {...gridState}
                    data={data}
                    onDataStateChange={(e: GridDataStateChangeEvent) => setGridDataState(e.dataState)}
                >
                    <GridColumn
                        width={60}
                        headerCell={() => (
                            <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => onBasketAddAll()} title={`Click to add all Reports on this page to the Basket.`}>
                                <BsBasket3Fill size={20} />
                            </button>
                        )}
                        title="Basket"
                        cell={(gridCellProps) => (
                            <CustomGridCell gridCellProps={gridCellProps} className={gridCellProps.className + " py-1"} style={gridCellProps.style}>
                                {nopimsBasketModel.items.every((i) => i.reportId !== gridCellProps.dataItem.ReportId) && (
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary btn-sm"
                                        onClick={() => onBasketAdd(gridCellProps.dataItem)}
                                        title="Click to add this Report to the Basket."
                                    >
                                        <BsBasket3Fill size={20} />
                                    </button>
                                )}
                            </CustomGridCell>
                        )}
                    />

                    <GridColumn
                        field="AttachmentManifestUrl"
                        title="Info"
                        width={90}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.AttachmentManifestUrl && <ExternalLinkView href={props.dataItem.AttachmentManifestUrl}>Listing</ExternalLinkView>}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="AttachmentArchiveFileName"
                        title="Download"
                        width={160}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.AttachmentArchiveUrl && <ExternalLinkView href={props.dataItem.AttachmentArchiveUrl}>{props.dataItem.AttachmentArchiveFileName}</ExternalLinkView>}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="AttachmentArchiveFileSize"
                        title="File Size"
                        width={140}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                {props.dataItem.AttachmentArchiveFileSize && GlobalHelpers.convertBytesToString(props.dataItem.AttachmentArchiveFileSize)}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="ReportTitle" title="Data Description" width={600} filter="text" />
                    <GridColumn field="ReportType" title="Report Type" width={200} />
                    <GridColumn field="Company" title="Company" width={250} />
                    <GridColumn field="TapeId" title="Tape ID" width={100} />
                    <GridColumn field="ReportItemId" title="Item ID" width={100} />
                    <GridColumn field="ReprocessingIdentifier" filter="text" title="Reprocessing ID" width={150} />
                    <GridColumn field="ReprocessingName" filter="text" title="Reprocessing" width={500} />
                    <GridColumn field="ReprocessingProjectIdentifier" filter="text" title="Project ID" width={200} />
                    <GridColumn field="ReprocessingProjectName" filter="text" title="Project" width={500} />
                </Grid>
            </div>
        </>
    );
}

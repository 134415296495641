// framework
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as Actions from "../redux/actions";
import { useNopimsBasket } from "../redux/reducers";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// model
import { INopimsBasketItemModel } from "../redux/model";
// common
import CustomGridCell from "../../../common/kendoGrid/CustomGridCell";
import * as Forms from "../../../common/forms/BootstrapForms";

interface IProps {
    onShowSubmitBasket: () => void;
}

export default function BasketView(props: IProps): React.JSX.Element {
    const nopimsBasketModel = useNopimsBasket();
    const dispatch = useDispatch();

    // grid state
    const initialGridState = {
        sort: [{ field: "title", dir: "asc" }],
        skip: 0,
        take: 10,
    } as State;
    const [gridState, setGridState] = useState(initialGridState);

    function onItemRemoved(item: INopimsBasketItemModel): void {
        dispatch(Actions.itemsRemoved([item]));
    }

    function onItemsCleared(): void {
        dispatch(Actions.itemsRemoved(nopimsBasketModel.items));
    }

    return (
        <>
            <Forms.Row>
                <Forms.HalfColumn>
                    {nopimsBasketModel.items.length > 0 ? (
                        <>
                            You are requesting copies of the following <b>{nopimsBasketModel.items.length.toString()} documents</b>. Review the list below and press "Proceed".
                        </>
                    ) : (
                        <>Your basket is empty.</>
                    )}
                </Forms.HalfColumn>
            </Forms.Row>
            <Grid
                pageable
                sortable
                resizable
                navigatable
                data={process(nopimsBasketModel.items, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    width={82}
                    cell={(gridCellProps) => (
                        <CustomGridCell gridCellProps={gridCellProps} className={gridCellProps.className + " py-1"} style={gridCellProps.style}>
                            <button
                                className="btn btn-secondary btn-sm"
                                type="button"
                                onClick={() => onItemRemoved(gridCellProps.dataItem)}
                                title={`Click to remove this ${gridCellProps.dataItem.activity}'s Document from the Basket.`}
                            >
                                Remove
                            </button>
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="activity" title="Activity Type" width={110} />
                <GridColumn field="activityIdentifier" title="Activity ID" width={130} />
                <GridColumn field="activityName" title="Activity Name" width={300} />
                <GridColumn field="dataDescription" title="Data Description" width={400} />
                <GridColumn field="documentType" title="Document Type" width={160} />
                <GridColumn field="type" title="Report/Data Type" width={200} />
                <GridColumn field="itemId" title="Item ID" width={200} />
            </Grid>
            <div className="mt-2">
                <button
                    className="btn btn-primary ms-2 px-2"
                    type="button"
                    disabled={nopimsBasketModel.items.length === 0}
                    onClick={() => onItemsCleared()}
                    title="Click to Remove All Documents from the Basket."
                >
                    Remove All
                </button>
                <button
                    className="btn btn-primary ms-2 px-2"
                    type="button"
                    disabled={nopimsBasketModel.items.length === 0}
                    onClick={() => props.onShowSubmitBasket()}
                    title="Click to proceed to the Request form to submit the Basket."
                >
                    Proceed
                </button>
            </div>
        </>
    );
}

// framework
import { takeEvery, call, put } from "redux-saga/effects";
// redux
import { shellActions, refreshStaticConfigurationResponseAction } from "./shellActions";
// api
import { createShellClient } from "../../api/util";
import { simpleCall } from "../../api/callHelper";
import * as Client from "../../api/Client";
// shell
import { setBusyAction, clearBusyAction } from "../../shell/redux/shellActions";

export default function* watcherSaga() {
    yield takeEvery(shellActions.refreshStaticConfigurationRequest, workerSaga);
}

function* workerSaga() {
    try {
        // start query
        yield put(setBusyAction());
        const featureSwitchesResponse: Client.GetShellFeatureSwitchesResponseDto = yield call(getFeatureSwitches);
        const portalLinksResponse: Client.GetPublicShellPortalLinksResponseDto = yield call(getPortalLinks);
        // post response
        yield put(refreshStaticConfigurationResponseAction(featureSwitchesResponse, portalLinksResponse));
    } catch {
        // suppress
    } finally {
        yield put(clearBusyAction());
    }
}

async function getFeatureSwitches(): Promise<Client.GetPublicShellFeatureSwitchesResponseDto> {
    const client = createShellClient();

    const request = new Client.GetPublicShellFeatureSwitchesRequestDto();

    const response = await simpleCall(() => client.getPublicShellFeatureSwitches(request));
    return response;
}

async function getPortalLinks(): Promise<Client.GetPublicShellPortalLinksResponseDto> {
    const client = createShellClient();

    const request = new Client.GetPublicShellPortalLinksRequestDto();

    const response = await simpleCall(() => client.getPublicShellPortalLinks(request));
    return response;
}

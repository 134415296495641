// framework
import { useState, useEffect } from "react";
import { IoAddCircleOutline, IoCheckmarkCircle } from "react-icons/io5";
// kendo
import { Grid, GridColumn, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { DataResult, State, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { NumericFilter, DateFilter, FilterChangeEvent } from "@progress/kendo-react-data-tools";
// models
import { ISurveySearchDataRow } from "./models";
// common
import ODataReader from "../../../common/odata/ODataReader";
import * as GlobalHelpers from "../../../common/GlobalHelpers";
import CustomGridCell from "../../../common/kendoGrid/CustomGridCell";
import CommentSummaryView from "../../../common/commentSummary/commentSummaryView";
import GridFilter from "../../../common/kendoGrid/GridFilter";
import { getDateFilter, getNumericFilter, getTextFilter } from "../../../common/kendoGrid/GridFilterOptions";
import { TextBoxFilter } from "../../../common/kendoGrid/GridFilterTextBoxFilter";
import CopyODataLinkView from "../../../common/odata/CopyODataLinkView";

interface IProps {
    selectedItems: Array<ISurveySearchDataRow>;
    onAdd: (items: Array<ISurveySearchDataRow>) => void;
    onRemove: (item: ISurveySearchDataRow) => void;
}

export default function Controller(props: IProps) {
    const pageSize: number = 10;
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [
            {
                field: "SurveyName",
                operator: "contains",
                value: "",
            },
        ],
    };

    const [gridState, setGridDataState] = useState<State>({
        sort: [{ field: "StartDate", dir: "desc" }],
        take: pageSize,
        skip: 0,
        filter: initialFilter,
    });

    const [filter, setFilter] = useState<CompositeFilterDescriptor>(initialFilter);
    const onFilterChange = (event: FilterChangeEvent) => {
        setFilter(event.filter);
        setGridDataState({ ...gridState, filter: event.filter });
    };

    const [data, setData] = useState<DataResult>({ data: [], total: 0 });
    function onDataChange(data: DataResult): void {
        setData(data);
    }

    function mapDataRow(dataRow: any): ISurveySearchDataRow {
        return {
            SurveyId: dataRow.SurveyId,
            AcquisitionId: dataRow.AcquisitionId,
            SurveyIdentifier: dataRow.SurveyIdentifier,
            SurveyName: dataRow.SurveyName,
            AcquisitionIdentifier: dataRow.AcquisitionIdentifier,
            AcquisitionName: dataRow.AcquisitionName,
            Alias: dataRow.Alias,
            SurveyType: dataRow.SurveyType,
            StartDate: dataRow.StartDate ? new Date(dataRow.StartDate) : null,
            EndDate: dataRow.EndDate ? new Date(dataRow.EndDate) : null,
            SurveyOperator: dataRow.SurveyOperator,
            AcquisitionContractor: dataRow.AcquisitionContractor,
            Basins: dataRow.Basins,
            Terrain: dataRow.Terrain,
            BasicRelevantDate: dataRow.BasicRelevantDate ? new Date(dataRow.BasicRelevantDate) : null,
            InterpretativeRelevantDate: dataRow.InterpretativeRelevantDate ? new Date(dataRow.InterpretativeRelevantDate) : null,
            BasicReleaseDate: dataRow.BasicReleaseDate ? new Date(dataRow.BasicReleaseDate) : null,
            InterpretativeReleaseDate: dataRow.InterpretativeReleaseDate ? new Date(dataRow.InterpretativeReleaseDate) : null,
            Titles: dataRow.Titles,
            NopimsLegislation: dataRow.NopimsLegislation,
            TotalLineLength: dataRow.TotalLineLength,
            AreaSqKm: dataRow.AreaSqKm,
            ExclusivityStatus: dataRow.ExclusivityStatus,
            Vessel: dataRow.Vessel,
            Comments: dataRow.Comments,
            Subset2DRelevantDate: dataRow.Subset2DreleaseDate ? new Date(dataRow.Subset2DrelevantDate) : null,
            Subset2DReleaseDate: dataRow.Subset2DreleaseDate ? new Date(dataRow.Subset2DreleaseDate) : null,
        };
    }

    useEffect(() => {
        if (data.data.length === 0 && data.total > 0 && gridState && gridState.skip! > 0) setGridDataState({ ...gridState, skip: 0 });
    }, [data, gridState]);

    // return
    return (
        <>
            <div className="row">
                <div className="col">
                    <GridFilter
                        value={filter}
                        onChange={onFilterChange}
                        fields={[
                            { name: "SurveyIdentifier", label: "Survey ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                            { name: "SurveyName", label: "Survey", filter: TextBoxFilter, operators: getTextFilter(false) },
                            { name: "AcquisitionIdentifier", label: "Acquisition ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                            { name: "AcquisitionName", label: "Acquisition", filter: TextBoxFilter, operators: getTextFilter(false) },
                            { name: "Alias", label: "Aliases", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "SurveyType", label: "Survey Type", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "StartDate", label: "Start Date", filter: DateFilter, operators: getDateFilter(true) },
                            { name: "EndDate", label: "End Date", filter: DateFilter, operators: getDateFilter(true) },
                            { name: "SurveyOperator", label: "Survey Operator", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "AcquisitionContractor", label: "Acquisition Contractor", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "Basins", label: "Basins", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "Terrain", label: "Terrain", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "BasicRelevantDate", label: "Basic Relevant Date", filter: DateFilter, operators: getDateFilter(true) },
                            { name: "InterpretativeRelevantDate", label: "Interp Relevant Date", filter: DateFilter, operators: getDateFilter(true) },
                            { name: "BasicReleaseDate", label: "Basic Release Date", filter: DateFilter, operators: getDateFilter(true) },
                            { name: "InterpretativeReleaseDate", label: "Interp Release Date", filter: DateFilter, operators: getDateFilter(true) },
                            { name: "Titles", label: "Titles", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "NopimsLegislation", label: "Legislation", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "TotalLineLength", label: "Total Line Length (km)", filter: NumericFilter, operators: getNumericFilter(true) },
                            { name: "AreaSqKm", label: "Area (km²)", filter: NumericFilter, operators: getNumericFilter(true) },
                            { name: "ExclusivityStatus", label: "Exclusivity", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "Vessel", label: "Vessel", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "Comments", label: "Comments", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "Subset2DRelevantDate", label: "Subset 2D Relevant Date", filter: DateFilter, operators: getDateFilter(true) },
                            { name: "Subset2DReleaseDate", label: "Subset 2D Release Date", filter: DateFilter, operators: getDateFilter(true) },
                        ]}
                    />
                </div>
                <div className="col-auto">
                    <div className="mt-2 float-right">
                        <CopyODataLinkView title="Survey OData" toolTip="Survey" oDataPath="/odata/v1/public/nopims/survey/PublicNopimsSurvey" />
                    </div>
                </div>
            </div>

            <ODataReader
                url="odata/v1/public/nopims/survey/PublicNopimsSurvey"
                showContentAsBusyElementId="SurveySearchResultsGrid"
                dataState={gridState}
                onDataReceived={onDataChange}
                mapDataRow={mapDataRow}
            />
            <div className="mb-4">
                <Grid
                    id="SurveySearchResultsGrid"
                    sortable
                    pageable={{ pageSizeValue: pageSize, responsive: false }}
                    resizable
                    navigatable
                    {...gridState}
                    data={data}
                    onDataStateChange={(e: GridDataStateChangeEvent) => setGridDataState(e.dataState)}
                >
                    <GridColumn
                        locked={true}
                        width={60}
                        headerCell={() => (
                            <button
                                type="button"
                                className="btn btn-outline-secondary btn-sm"
                                title={`Click to add all Acquisitions on this page to the Selected Acquisitions.`}
                                onClick={() => props.onAdd(data.data)}
                            >
                                <IoAddCircleOutline size={20} />
                            </button>
                        )}
                        cell={(gridCellProps) => (
                            <CustomGridCell gridCellProps={gridCellProps} className={gridCellProps.className + " py-1"} style={gridCellProps.style}>
                                {!props.selectedItems.find((i) => i.AcquisitionId === (gridCellProps.dataItem as ISurveySearchDataRow).AcquisitionId) ? (
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary btn-sm"
                                        title="Click to add this Acquisition to the Selected Acquisitions."
                                        onClick={() => props.onAdd([gridCellProps.dataItem])}
                                    >
                                        <IoAddCircleOutline size={20} />
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary btn-sm"
                                        title="Click to remove this Acquisition from the Selected Acquisitions."
                                        onClick={() => props.onRemove(gridCellProps.dataItem)}
                                    >
                                        <IoCheckmarkCircle size={20} color="green" />
                                    </button>
                                )}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="SurveyIdentifier" title="Survey ID" width={150} />
                    <GridColumn field="SurveyName" title="Survey" width={350} />
                    <GridColumn field="AcquisitionIdentifier" title="Acquisition ID" width={150} />
                    <GridColumn field="AcquisitionName" title="Acquisition" width={350} />
                    <GridColumn field="Alias" title="Aliases" width={250} />
                    <GridColumn field="SurveyType" title="Survey Type" width={200} />
                    <GridColumn field="StartDate" title="Start Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="EndDate" title="End Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="SurveyOperator" title="Survey Operator" width={250} />
                    <GridColumn field="AcquisitionContractor" title="Acquisition Contractor" width={250} />
                    <GridColumn field="Basins" title="Basins" width={250} />
                    <GridColumn field="Terrain" title="Terrain" width={250} />
                    <GridColumn field="BasicRelevantDate" title="Basic Relevant Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="InterpretativeRelevantDate" title="Interp Relevant Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="BasicReleaseDate" title="Basic Release Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="InterpretativeReleaseDate" title="Interp Release Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="Titles" title="Titles" width={250} />
                    <GridColumn field="NopimsLegislation" title="Legislation" width={250} />
                    <GridColumn field="TotalLineLength" title="Total Line Length (km)" format={"{0:N}"} width={200} />
                    <GridColumn field="AreaSqKm" title="Area (km²)" format={"{0:N}"} width={200} />
                    <GridColumn field="ExclusivityStatus" title="Exclusivity" width={250} />
                    <GridColumn field="Vessel" title="Vessel" width={250} />
                    <GridColumn
                        title="Comments"
                        width={460}
                        field="Comments"
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props} className="k-command-cell">
                                <CommentSummaryView comment={props.dataItem.Comments} maxSummaryLength={50} title="Comments"></CommentSummaryView>
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="Subset2DRelevantDate" title="Subset 2D Relevant Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={220} />
                    <GridColumn field="Subset2DReleaseDate" title="Subset 2D Release Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={220} />
                </Grid>
            </div>
        </>
    );
}
